<template>
  <v-container>
    <v-row dense>
      <v-col
        class="text-h3"
        align="center"
      >
        {{ category.name }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        class="text-h5"
        align="center"
      >
        {{ category.sub_name }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="6"
        class="text-body"
      >
        <v-expansion-panels v-model="description_show">
          <v-expansion-panel @click="technical_info_show = undefined">
            <v-expansion-panel-header class="text-h6">
              O čo nám ide?
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndDown">
              <html-replace :html="category.description"></html-replace>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="text-body"
      >
        <v-expansion-panels v-model="technical_info_show">
          <v-expansion-panel @click="description_show = undefined">
            <v-expansion-panel-header class="text-h6">
              Technické informácie
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndDown">
              <html-replace :html="category.technical_info"></html-replace>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <div v-show="!$vuetify.breakpoint.smAndDown">
      <v-row
        no-gutters
        v-show="description_show == 0"
      >
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <html-replace :html="category.description"></html-replace>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        v-show="technical_info_show == 0"
      >
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <html-replace :html="category.technical_info"></html-replace>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-row
      justify="space-between"
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        md="6"
        align="left"
        align-self="left"
      >
        <v-text-field
          v-model="search"
          label="Vyhľadaj"
          clearable
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        align="right"
      >
        <v-btn
          v-if="filter_favorite"
          text
          color="amber amber-darken-4"
          @click="filter_favorite = false"
        >
          Iba obľúbené &nbsp;<v-icon>mdi-star</v-icon>
        </v-btn>

        <v-btn
          v-else
          text
          color="amber amber-darken-4"
          @click="filter_favorite = true"
        >
          Iba obľúbené &nbsp;<v-icon>mdi-star-outline</v-icon>
        </v-btn>

      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col
        cols="12"
        align="left"
        align-self="left"
      >
        <v-list rounded>
          <v-list-item-group color="primary">
            <v-row
              v-for="poll in polls"
              v-bind:key="poll.id"
              align="center"
            >
              <v-col cols="11">
                  <v-list-item
                    :to="poll.url ? poll.url : '/poll_category/' + category.id + '/poll/' + poll.id + '/'"
                  >
                    <template v-slot:default="{ }">
                      <v-list-item-content
                        tag="span"
                        class="primary--text text-button"
                      >
                        {{ poll.name }}
                      </v-list-item-content>

                      <span
                        v-if="poll.not_voted_questions_count"
                        class="red--text lighten-3--text"
                      >({{ poll.not_voted_questions_count }})</span>
                    </template>
                  </v-list-item>
              </v-col>
              <v-col
                cols="1"
                align="right"
                align-self="left"
              >
                <v-btn
                  v-if="poll.user_favorite"
                  icon
                  color="amber amber-darken-4"
                  @click="removeFavorite(poll.id)"
                ><v-icon>mdi-star
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  icon
                  color="amber amber-darken-4"
                  @click="addFavorite(poll.id)"
                ><v-icon>mdi-star-outline
                  </v-icon>
                </v-btn>

              </v-col>
            </v-row>

          </v-list-item-group>

        </v-list>
      </v-col>
    </v-row>

  </v-container>
</template>
    
<script>
import { mapGetters } from "vuex";

import { APIService } from "@/http/APIService.js";

import HtmlReplace from '../HtmlReplace.vue';


const apiService = new APIService();


export default {
  name: 'Poll',
  inheritAttrs: true,

  components: {
    HtmlReplace
  },

  props: {
    categoryID: { type: Number, required: true },
  },

  data() {
    return {
      category: {
        polls: []
      },
      description_show: undefined,
      technical_info_show: undefined,

      filter_favorite: false,
      search: ""
    }
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

    polls() {
      let tmp = [...this.category.polls];
      if (this.filter_favorite)
        tmp = tmp.filter((poll) => poll.user_favorite);

      if (this.search)
        tmp = tmp.filter((poll) => poll.name.toLowerCase().includes(this.search.toLowerCase()));

      return tmp;
    }
  },


  events: {},

  watch: {
  },

  mounted: function () {
    this.getCategory();
  },

  methods: {
    removeFavorite(id) {
      apiService.generalPollRemoveFavorite(id).then(() => {
        this.getCategory();
      });
    },

    addFavorite(id) {
      apiService.generalPollAddFavorite(id).then(() => {
        this.getCategory();
      });
    },

    getCategory() {
      apiService.getGeneralPollCategory(this.categoryID).then(response => {
        this.category = response;
      });
    }
  },
};
</script>